import React from 'react';
import { BrowserView, MobileOnlyView } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import ReactSwipeButton from 'react-slide-button';
import { Box, Col, Inline, Row } from 'jsxstyle';

import useTheme from 'hooks/useTheme';

import {
    formatDeliveryWindowText,
    getOperationHourText,
    isHebrew,
    isStoreOpen,
    isSuperFastOrder,
    priceToString
} from 'api/utils';

import { useAppContext } from 'store';

import listArrow from 'assets/images/list-arrow.svg';

import Heading from 'components/Heading';
import Icon from 'components/Icon';
import Image from 'components/Image';
import { Body, Caption, Divider, Subtitle } from 'components/shared';
import Spinner from 'components/Spinner';

import SaveButton from './SaveButton';

export default function Main({
    thread,
    charges,
    deliveryOptions,
    onOptionSelected,
    submitting,
    onSubmit,
    saveEnabled,
    currentDeliveryModel,
    pickupLocation,
    timeZone,
    storeOpen
}) {
    const { theme, themeColors } = useTheme();
    const { state } = useAppContext();
    const { t } = useTranslation();

    const isSuperFast = isSuperFastOrder(currentDeliveryModel);

    const scheduledFor = thread.delivery_window
        ? formatDeliveryWindowText(thread.delivery_window, !storeOpen, t).toUpperCase()
        : '';

    const pickupReadyText = isHebrew() ? (
        <Box>
            {t('buyNowModal.main.pickupReady')}
            <Box>{pickupLocation?.default_pickup_estimate}</Box>
        </Box>
    ) : (
        t('buyNowModal.main.pickupReady', {
            time: pickupLocation?.default_pickup_estimate
        })
    );

    const placeOrderInfo = pickupLocation ? (
        pickupReadyText
    ) : isSuperFast ? (
        t('buyNowModal.main.isSuperFast')
    ) : isHebrew() ? (
        <Box>
            {t('buyNowModal.main.scDeliveredBetween')}
            <br />
            {scheduledFor}
        </Box>
    ) : (
        t('buyNowModal.main.scDeliveredBetween', { deliverBetween: scheduledFor })
    );

    const pickupInfo = pickupLocation && (
        <Row alignItems="center">
            <Icon name={storeOpen ? 'store' : 'storeRed'} />
            <Inline marginLeft={5} marginTop={3}>
                {getOperationHourText(pickupLocation.availability, timeZone, t)}
            </Inline>
        </Row>
    );

    return (
        <Col>
            <Box>
                <Heading color={themeColors[theme].blackFontColor} marginBottom={10}>
                    {t('buyNowModal.main.title')}
                </Heading>
                <Box width="100%">
                    <Subtitle fontWeight={700} opacity={0.7}>
                        {pickupLocation
                            ? t('buyNowModal.main.pickupTitle', {
                                  storeName: thread.pickup_destination.name
                              })
                            : t('buyNowModal.main.subtitle')}
                    </Subtitle>
                    <Subtitle overflow="hidden" maxHeight={19} whiteSpace="nowrap" opacity={0.7}>
                        {pickupLocation
                            ? thread?.pickup_destination?.location.address
                            : thread.address}
                    </Subtitle>
                    <Box
                        fontSize={14}
                        fontWeight={600}
                        fontStyle="italic"
                        opacity={pickupLocation && !storeOpen ? 1 : 0.6}
                        color={
                            pickupLocation && !storeOpen && themeColors[theme].quaternaryFontColor2
                        }
                    >
                        {pickupLocation ? (
                            pickupInfo
                        ) : isSuperFast ? (
                            <Row>
                                <Icon width={16} name="superFast" />
                                {t('buyNowModal.main.getItSf')}
                            </Row>
                        ) : (
                            <Row alignItems="center">
                                <Icon width={16} name="scheduled" />
                                {isHebrew()
                                    ? `${scheduledFor ? scheduledFor : ''}\u200E ${t(
                                          'buyNowModal.main.scheduledFor'
                                      )}`
                                    : `${t('buyNowModal.main.scheduledFor')} ${
                                          scheduledFor ? scheduledFor : ''
                                      }`}
                            </Row>
                        )}
                    </Box>
                </Box>

                <Divider opacity={0.3} marginTop={26} />
                <Col>
                    {deliveryOptions.map(item => {
                        const { title, value, caption, page, opacity } = item;
                        const onClick = () => {
                            onOptionSelected(page);
                        };
                        return (
                            <Box
                                key={page}
                                cursor="pointer"
                                backgroundColor="transparent"
                                props={{
                                    onClick
                                }}
                            >
                                <Row
                                    marginTop={21}
                                    marginBottom={21}
                                    alignItems="center"
                                    justifyContent="space-between"
                                >
                                    <Row maxWidth="100%">
                                        <Box fontWeight={600}>{title}</Box>
                                        <Body
                                            marginLeft={15}
                                            opacity={opacity || 0.5}
                                            maxWidth="80%"
                                            maxHeight={19}
                                        >
                                            {value}
                                        </Body>
                                    </Row>
                                    <Row alignItems="center">
                                        {caption && <Caption marginRight={5}>{caption}</Caption>}
                                        <Image width={7} height={14} src={listArrow} />
                                    </Row>
                                </Row>
                                <Divider opacity={0.3} />
                            </Box>
                        );
                    })}

                    <Box marginTop={30}>
                        <Row alignItems="center">
                            <Box fontWeight={600} marginRight={11}>
                                {t('buyNowModal.main.total')}
                            </Box>
                            <Body color={themeColors[theme].quaternaryFontColor1} marginRight={6}>
                                {priceToString(charges.total, state.currency)}
                            </Body>
                            <Caption>{t('buyNowModal.taxIncludedCaption')}</Caption>
                        </Row>
                    </Box>
                    <Box marginTop={51}>
                        {submitting ? (
                            <Row justifyContent="center">
                                <Spinner />
                            </Row>
                        ) : (
                            <Box>
                                <MobileOnlyView>
                                    <Col
                                        pointerEvents={saveEnabled ? 'auto' : 'none'}
                                        opacity={saveEnabled ? 1 : 0.3}
                                    >
                                        <ReactSwipeButton
                                            mainText={t(
                                                'buyNowModal.main.placeOrderSwipeButtonTitle'
                                            )}
                                            overlayText=""
                                            classList="swipe-button"
                                            overlayClassList="swipe-button-overlay"
                                            // color={themeColors[theme].blackFontColor}
                                            paddingBottom={20}
                                            onSlideDone={onSubmit}
                                            caret={<Box className="swipe-button-caret" />}
                                        />
                                        <Subtitle textAlign="center" marginTop={20}>
                                            {placeOrderInfo}
                                        </Subtitle>
                                    </Col>
                                </MobileOnlyView>
                                <BrowserView>
                                    <Row justifyContent="space-between" alignItems="center">
                                        <Subtitle width={336}>{placeOrderInfo}</Subtitle>
                                        <SaveButton
                                            disabled={!saveEnabled}
                                            width={260}
                                            onClick={onSubmit}
                                            title={t('buyNowModal.main.placeOrder')}
                                            loading={submitting}
                                        >
                                            {t('buyNowModal.main.placeOrderButtonTitle')}
                                        </SaveButton>
                                    </Row>
                                </BrowserView>
                            </Box>
                        )}
                    </Box>
                </Col>
            </Box>
        </Col>
    );
}
