import useSWR from 'swr';

import api from '../api';
import { getDefaultNextDayWindow } from '../api/utils';
import useCustomerData from './useCustomerData';
import useStoreSettings from './useStoreSettings';

export default function useWindows() {
    const { data: storeSettings } = useStoreSettings();
    const { data: mappedData, mutate: revalidateCustomerData } = useCustomerData();
    const { thread } = mappedData || {};
    const key = () => [`windows`, storeSettings?.organization_id, thread?.zoneId];
    const options = {
        shouldRetryOnError: true,
        errorRetryInterval: 30000,
        dedupingInterval: 60000,
        focusThrottleInterval: 60000,
        revalidateOnFocus: true
    };
    const fetch = async () => {
        if (!thread || !thread.zoneId) return null;
        const data = await api.getWindows(storeSettings?.organization_id, thread?.zoneId);
        const { windows } = data;
        if (!windows.length) {
            const nextDayWindow = getDefaultNextDayWindow(
                storeSettings.organization_id,
                storeSettings.availability,
                storeSettings.timezone
            );
            windows.push(nextDayWindow);
        }
        return windows;
    };

    const { data, error, mutate, isValidating } = useSWR(key, fetch, options);

    async function updateThreadDeliveryModel(delivery_model, window) {
        const response = await api.updateThreadDeliveryModel(
            mappedData.user,
            storeSettings?.organization_id,
            thread?.id,
            delivery_model,
            thread?.menu_kit,
            window,
            thread?.thread_status
        );
        revalidateCustomerData();
        return response;
    }

    return {
        data,
        error,
        mutate,
        isValidating,
        updateThreadDeliveryModel
    };
}
